import gPhoneNumber from 'google-libphonenumber'
import * as Yup from 'yup'

const phoneUtil = gPhoneNumber.PhoneNumberUtil.getInstance()

declare module 'yup' {
	export interface StringSchema {
		/**
		 * Check for phone number validity.
		 * @param {String} [countryCode=IN] The country code to check against.
		 * @param {Boolean} [strict=false] How strictly should it check.
		 * @param {String} [errorMessage=DEFAULT_MESSAGE] The error message to return if the validation fails.
		 */
		phone(
			countryCode?: string,
			strict?: boolean,
			errorMessage?: string
		): StringSchema
	}
}

const YUP_PHONE_METHOD = 'phone'
const CLDR_REGION_CODE_SIZE = 2
const isValidCountryCode = (countryCode: any): boolean =>
	typeof countryCode === 'string' &&
	countryCode.length === CLDR_REGION_CODE_SIZE

// eslint-disable-next-line func-names
Yup.addMethod(
	Yup.string,
	YUP_PHONE_METHOD,
	function yupPhone(countryCode?: string, strict = false, errorMessage = '') {
		const errMsg =
			typeof errorMessage === 'string' && errorMessage ? errorMessage : '76'
		// @ts-ignore
		return this.test(YUP_PHONE_METHOD, errMsg, (value: string) => {
			if (!isValidCountryCode(countryCode)) {
				// If not valid countryCode, then set default country to Belgium (BE)
				countryCode = 'BE'
				strict = false
			}

			if (value === undefined) {
				return true
			}

			try {
				const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCode)

				if (!phoneUtil.isPossibleNumber(phoneNumber)) {
					return false
				}

				const regionCodeFromPhoneNumber =
					phoneUtil.getRegionCodeForNumber(phoneNumber)

				/* Check if the countryCode provided should be used as
			 default country code or strictly followed
		 */
				if (strict) {
					return phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)
				}

				return phoneUtil.isValidNumberForRegion(
					phoneNumber,
					regionCodeFromPhoneNumber
				)
			} catch {
				return false
			}
		})
	}
)
