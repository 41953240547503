import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	fileUpload: {
		marginBottom: theme.spacing(160 / 8),
	},
	uploadTitle: {
		fontSize: 24,
		fontWeight: 700,
		lineHeight: 'normal',
		letterSpacing: -0.33,
		marginBottom: theme.spacing(12 / 8),
	},
	uploadDescription: {
		fontSize: 16,
		fontWeight: 500,
		lineHeight: 'normal',
		letterSpacing: 0.19,
		color: '#7e7e80',
		marginBottom: theme.spacing(24 / 8),
	},
}))

export default useStyles
