import {Box} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

import CustomSelect from '@components/CustomSelect'
import CustomTextInput from '@components/CustomTextInput'
import {ValutaInputIconLeftComponent as ValutaInputIconLeft} from '@components/index'
import {
	CreateQuotationFormFields,
	QuotationFormComponentProps,
} from '@templates/QuotationCreation'
import {ProductData} from '@utils/types'

import useStyles from './style.hook'

interface BicycleInfoProps extends QuotationFormComponentProps {
	brands: ProductData[]
	types: ProductData[]
	frameTypes: ProductData[]
	frameSizes: ProductData[]
	colors: ProductData[]
}

type StringValues = 'model'

const BicycleInfo: React.FC<BicycleInfoProps> = ({
	values,
	touched,
	errors,
	handleBlur,
	handleChange,
	brands,
	types,
	frameTypes,
	frameSizes,
	colors,
}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})

	const brandSelectItems = brands.map((p: ProductData) => ({
		value: p.productDataId,
		label: p.displayName,
	}))

	const typeSelectItems = types.map((p: ProductData) => ({
		value: p.productDataId,
		label: p.displayName,
	}))

	const frameTypeSelectItems = frameTypes.map((p: ProductData) => ({
		value: p.productDataId,
		label: p.displayName,
	}))

	const frameSizeSelectItems = frameSizes.map((p: ProductData) => ({
		value: p.productDataId,
		label: p.displayName,
	}))

	const colorSelectItems = colors.map((p: ProductData) => ({
		value: p.productDataId,
		label: p.displayName,
	}))

	const trimSpaces = (
		values: CreateQuotationFormFields,
		property: StringValues
	): void => {
		values[property] = values[property].trim()
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.selectContainer}>
				<CustomSelect
					id={`quotation-create-form-brand-select`}
					label={t('QuotationBrand')}
					className={classes.selectInput}
					items={brandSelectItems}
					error={(errors.brandId && touched.brandId) as boolean}
					helperText={(touched.brandId && errors.brandId) as string}
					helperTextId={errors.brandId && 'brand-helper-text'}
					placeholder={t('QuotationCreationSelectPlaceholder')}
					propsToDelegate={{
						name: 'brandId',
						value: values.brandId,
						onChange: handleChange,
						onBlur: handleBlur,
					}}
					noTranslateItems
				/>
			</Box>
			<CustomTextInput
				id={`quotation-create-form-model-input`}
				label={t('QuotationModel')}
				error={(errors.model && touched.model) as boolean}
				helperText={(touched.model && errors.model) as string}
				helperTextId={errors.model && 'model-helper-text'}
				className={classes.input}
				propsToDelegate={{
					placeholder: t('QuotationTypeHere'),
					name: 'model',
					type: 'text',
					value: values.model,
					error: (errors.model && touched.model) as boolean,
					onChange: handleChange,
					onBlur: (e): void => {
						handleBlur(e)
						trimSpaces(values, 'model')
					},
				}}
			/>
			<Box className={classes.selectContainer}>
				<CustomSelect
					id={'quotation-form-type-select'}
					label={t('QuotationType')}
					className={classes.selectInput}
					items={typeSelectItems}
					error={(errors.typeId && touched.typeId) as boolean}
					helperText={(touched.typeId && errors.typeId) as string}
					helperTextId={errors.typeId && 'type-helper-text'}
					placeholder={t('QuotationCreationSelectPlaceholder')}
					propsToDelegate={{
						name: 'typeId',
						value: values.typeId,
						onChange: handleChange,
						onBlur: handleBlur,
					}}
					noTranslateItems
				/>
			</Box>
			<Box className={classes.selectContainer}>
				<CustomSelect
					id={'quotation-form-frame-type-select'}
					label={t('QuotationFrameType')}
					className={classes.selectInput}
					items={frameTypeSelectItems}
					error={(errors.frameTypeId && touched.frameTypeId) as boolean}
					helperText={(touched.frameTypeId && errors.frameTypeId) as string}
					helperTextId={errors.frameTypeId && 'frame-type-helper-text'}
					placeholder={t('QuotationCreationSelectPlaceholder')}
					propsToDelegate={{
						name: 'frameTypeId',
						value: values.frameTypeId,
						onChange: handleChange,
						onBlur: handleBlur,
					}}
					noTranslateItems
				/>
			</Box>
			<Box className={classes.selectContainer}>
				<CustomSelect
					id={'quotation-form-size-select'}
					label={t('QuotationFrameSize')}
					className={classes.selectInput}
					items={frameSizeSelectItems}
					error={(errors.frameSizeId && touched.frameSizeId) as boolean}
					helperText={(touched.frameSizeId && errors.frameSizeId) as string}
					helperTextId={errors.frameSizeId && 'frame-size-helper-text'}
					placeholder={t('QuotationCreationSelectPlaceholder')}
					propsToDelegate={{
						name: 'frameSizeId',
						value: values.frameSizeId,
						onChange: handleChange,
						onBlur: handleBlur,
					}}
				/>
			</Box>
			<Box className={classes.selectContainer}>
				<CustomSelect
					id={'quotation-form-color-select'}
					label={t('QuotationColor')}
					className={classes.selectInput}
					items={colorSelectItems}
					error={(errors.colorId && touched.colorId) as boolean}
					helperText={(touched.colorId && errors.colorId) as string}
					helperTextId={errors.colorId && 'color-helper-text'}
					placeholder={t('QuotationCreationSelectPlaceholder')}
					propsToDelegate={{
						name: 'colorId',
						value: values.colorId,
						onChange: handleChange,
						onBlur: handleBlur,
					}}
				/>
			</Box>
			<CustomTextInput
				id={`quotation-create-form-retail-price-input`}
				label={t('QuotationRetailPrice')}
				error={(errors.retailPrice && touched.retailPrice) as boolean}
				helperText={(touched.retailPrice && errors.retailPrice) as string}
				helperTextId={errors.retailPrice && 'retail-price-helper-text'}
				className={classes.input}
				iconLeft={<ValutaInputIconLeft />}
				propsToDelegate={{
					name: 'retailPrice',
					type: 'number',
					value: values.retailPrice,
					error: (errors.retailPrice && touched.retailPrice) as boolean,
					onChange: handleChange,
					onBlur: handleBlur,
				}}
			/>
		</Box>
	)
}

BicycleInfo.propTypes = {
	touched: PropTypes.any,
	errors: PropTypes.any,
	values: PropTypes.any,
	handleChange: PropTypes.any,
	handleBlur: PropTypes.any,
	brands: PropTypes.any,
	types: PropTypes.any,
	frameTypes: PropTypes.any,
	frameSizes: PropTypes.any,
	colors: PropTypes.any,
}

export default BicycleInfo
