import {TFunction} from 'i18next'
import * as Yup from 'yup'

import '@utils/yup/phone-validator'
import {FormValidationErrorMessages} from '@utils/types/form-validation-error-messages'

const createQuotationSchemaShape = (t: TFunction): any => ({
	reference: Yup.string().trim().max(49, 'Too Long!'),
	customerId: Yup.string()
		.trim()
		.nullable()
		.required(t(FormValidationErrorMessages.REQUIRED)),
	dealerId: Yup.string()
		.trim()
		.nullable()
		.required(t(FormValidationErrorMessages.REQUIRED)),
	cyclistEmail: Yup.string()
		.trim()
		.email(t(FormValidationErrorMessages.EMAIL))
		.max(
			99,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		),
	firstName: Yup.string()
		.trim()
		.max(
			49,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		),
	lastName: Yup.string()
		.trim()
		.max(
			49,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		),
	email: Yup.string()
		.trim()
		.email(t(FormValidationErrorMessages.EMAIL))
		.max(
			99,
			(obj) => `${t(FormValidationErrorMessages.NUMBER_MAX)} (Max. ${obj.max})`
		),
	preferredLanguage: Yup.string().trim(),
	phone: Yup.string()
		.trim()
		.phone(null as any, false, t(FormValidationErrorMessages.PHONE)),
	model: Yup.string()
		.trim()
		.max(
			249,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		)
		.required(t(FormValidationErrorMessages.REQUIRED)),
	retailPrice: Yup.number()
		.min(
			1,
			(obj) => `${t(FormValidationErrorMessages.NUMBER_MIN)} (Min. ${obj.min})`
		)
		.max(
			20000,
			(obj) => `${t(FormValidationErrorMessages.NUMBER_MAX)} (Max. ${obj.max})`
		)
		.required(t(FormValidationErrorMessages.REQUIRED)),
	brandId: Yup.string()
		.trim()
		.required(t(FormValidationErrorMessages.REQUIRED)),
	typeId: Yup.string().trim().required(t(FormValidationErrorMessages.REQUIRED)),
	frameTypeId: Yup.string()
		.trim()
		.required(t(FormValidationErrorMessages.REQUIRED)),
	frameSizeId: Yup.string()
		.trim()
		.required(t(FormValidationErrorMessages.REQUIRED)),
	colorId: Yup.string()
		.trim()
		.required(t(FormValidationErrorMessages.REQUIRED)),
	accessories: Yup.array().of(
		Yup.object().shape({
			description: Yup.string()
				.trim()
				.required(t(FormValidationErrorMessages.REQUIRED))
				.max(
					100,
					(obj) =>
						`${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
				),
			quantity: Yup.number()
				.typeError(t(FormValidationErrorMessages.NUMBER_TYPE_ERROR))
				.min(
					1,
					(obj) =>
						`${t(FormValidationErrorMessages.NUMBER_MIN)} (Min. ${obj.min})`
				)
				.required(t(FormValidationErrorMessages.REQUIRED)),
			pricePerPiece: Yup.number()
				.typeError(() => t(FormValidationErrorMessages.NUMBER_TYPE_ERROR))
				.min(0, t(FormValidationErrorMessages.NUMBER_NO_NEGATIVE))
				.required(t(FormValidationErrorMessages.REQUIRED)),
		})
	),
	administrationCosts: Yup.array().of(
		Yup.object().shape({
			description: Yup.string()
				.trim()
				.required(t(FormValidationErrorMessages.REQUIRED))
				.max(
					100,
					(obj) =>
						`${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
				),
			cost: Yup.number()
				.typeError(t(FormValidationErrorMessages.NUMBER_TYPE_ERROR))
				.min(0, t(FormValidationErrorMessages.NUMBER_NO_NEGATIVE))
				.required(t(FormValidationErrorMessages.REQUIRED)),
		})
	),
})

const createQuotationWithRequiredCyclistInfoSchemaShape = (
	t: TFunction
): any => ({
	...createQuotationSchemaShape,
	cyclistEmail: Yup.string()
		.trim()
		.email(t(FormValidationErrorMessages.EMAIL))
		.max(
			99,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		),
	firstName: Yup.string()
		.trim()
		.max(
			49,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		)
		.required(t(FormValidationErrorMessages.REQUIRED)),
	lastName: Yup.string()
		.trim()
		.max(
			49,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		)
		.required(t(FormValidationErrorMessages.REQUIRED)),
	email: Yup.string()
		.trim()
		.email(t(FormValidationErrorMessages.EMAIL))
		.max(
			99,
			(obj) => `${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
		)
		.required(t(FormValidationErrorMessages.REQUIRED)),
	preferredLanguage: Yup.string()
		.trim()
		.required(t(FormValidationErrorMessages.REQUIRED)),
	phone: Yup.string()
		.trim()
		.phone(null as any, false, t(FormValidationErrorMessages.PHONE))
		.required(t(FormValidationErrorMessages.REQUIRED)),
	accessories: Yup.array().of(
		Yup.object().shape({
			description: Yup.string()
				.trim()
				.required(t(FormValidationErrorMessages.REQUIRED))
				.max(
					100,
					(obj) =>
						`${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
				),
			quantity: Yup.number()
				.typeError(t(FormValidationErrorMessages.NUMBER_TYPE_ERROR))
				.min(
					1,
					(obj) =>
						`${t(FormValidationErrorMessages.NUMBER_MIN)} (Min. ${obj.min})`
				)
				.required(t(FormValidationErrorMessages.REQUIRED)),
			pricePerPiece: Yup.number()
				.typeError(() => t(FormValidationErrorMessages.NUMBER_TYPE_ERROR))
				.min(0, t(FormValidationErrorMessages.NUMBER_NO_NEGATIVE))
				.required(t(FormValidationErrorMessages.REQUIRED)),
		})
	),
	administrationCosts: Yup.array().of(
		Yup.object().shape({
			description: Yup.string()
				.trim()
				.required(t(FormValidationErrorMessages.REQUIRED))
				.max(
					100,
					(obj) =>
						`${t(FormValidationErrorMessages.STRING_MAX)} (Max. ${obj.max})`
				),
			cost: Yup.number()
				.typeError(t(FormValidationErrorMessages.NUMBER_TYPE_ERROR))
				.min(0, t(FormValidationErrorMessages.NUMBER_NO_NEGATIVE))
				.required(t(FormValidationErrorMessages.REQUIRED)),
		})
	),
})

export const createQuotationSchema = (t: TFunction): Yup.ObjectSchema<any> =>
	Yup.object().shape(createQuotationSchemaShape(t))
export const createQuotationWithRequiredCyclistInfoSchema = (
	t: TFunction
): Yup.ObjectSchema<any> =>
	Yup.object().shape(createQuotationWithRequiredCyclistInfoSchemaShape(t))
