import {Box, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import classNames from 'classnames'
import {Field, FieldArray, FormikErrors, FormikTouched} from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

import CustomButton from '@components/CustomButton'
import CustomCheckbox from '@components/CustomCheckbox'
import CustomSliderInput from '@components/CustomSliderInput'
import CustomTextInput from '@components/CustomTextInput'
import {ValutaInputIconLeftComponent as ValutaInputIconLeft} from '@components/index'
import {CloseIconSvg} from '@svg/index'
import {
	QuotationFormComponentProps,
	CreateQuotationFormFields,
} from '@templates/QuotationCreation'
import {Customer, PriceListOption} from '@utils/types'

import useStyles from './style.hook'

type StringValues = 'description'
interface AdditionalInfoProps extends QuotationFormComponentProps {
	baseMaintenanceCost: number
	customer: Customer
}

const AdditionalInfo: React.FC<AdditionalInfoProps> = ({
	values,
	handleChange,
	handleBlur,
	baseMaintenanceCost,
	setFieldValue,
	customer,
	errors,
	touched,
}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})

	const handleAdministrationCostsError = (
		errors: FormikErrors<CreateQuotationFormFields>,
		touched: FormikTouched<CreateQuotationFormFields>,
		index: number
	): boolean => {
		if (errors.administrationCosts && touched.administrationCosts) {
			if (
				errors.administrationCosts.length > index &&
				touched.administrationCosts.length > index
			) {
				return (
					Boolean(errors.administrationCosts[index]) &&
					Boolean(touched.administrationCosts[index])
				)
			}
		}

		return false
	}

	const getAdministrationCostsHelperText = (
		errors: FormikErrors<CreateQuotationFormFields>,
		touched: FormikTouched<CreateQuotationFormFields>,
		index: number,
		prop: 'description' | 'cost'
	): string => {
		if (
			errors.administrationCosts &&
			touched.administrationCosts &&
			touched.administrationCosts[index] &&
			errors.administrationCosts[index]
		) {
			// @ts-ignore
			const isTouched = touched.administrationCosts[index][prop]
			// @ts-ignore
			const error = errors.administrationCosts[index][prop]

			return isTouched && error
		}

		return ''
	}

	const trimAdministationCostsSpaces = (
		values: CreateQuotationFormFields,
		property: StringValues,
		index: number
	): void => {
		values.administrationCosts[index][property] =
			values.administrationCosts[index][property].trim()
	}

	return (
		<Box className={classes.root}>
			<Box className={classes.extrasBox}>
				<Typography
					id={`quotation-create-form-extras-title`}
					className={classes.title}
					variant={'h3'}
				>
					{t('QuotationExtrasToInclude')}
				</Typography>
				<Box className={classes.checkboxContainer}>
					<CustomCheckbox
						id={`quotation-create-form-insurance-checkbox`}
						propsToDelegate={{
							checked:
								(customer &&
									customer.insurance === PriceListOption.NO &&
									false) ||
								(values && values.insurance) ||
								(customer && customer.insurance === PriceListOption.REQUIRED),
							onChange: handleChange,
							disabled:
								customer &&
								(customer.insurance === PriceListOption.NO ||
									customer.insurance === PriceListOption.REQUIRED),
							name: 'insurance',
							id: t('QuotationInsurance'),
						}}
						label={t('QuotationInsurance')}
					/>
				</Box>
				<Box className={classes.checkboxContainer}>
					<CustomCheckbox
						id={`quotation-create-form-assistance-checkbox`}
						propsToDelegate={{
							checked:
								(customer &&
									customer.assistance === PriceListOption.NO &&
									false) ||
								(values && values.assistance) ||
								(customer && customer.assistance === PriceListOption.REQUIRED),
							disabled:
								customer &&
								(customer.assistance === PriceListOption.NO ||
									customer.assistance === PriceListOption.REQUIRED),
							onChange: handleChange,
							name: 'assistance',
							id: t('QuotationAssistance'),
						}}
						label={t('QuotationAssistance')}
					/>
				</Box>
				<Box className={classes.checkboxContainer}>
					<CustomCheckbox
						id={`quotation-create-form-maintenance-checkbox`}
						propsToDelegate={{
							checked:
								(customer &&
									customer.maintenance === PriceListOption.NO &&
									false) ||
								(values && values.maintenance) ||
								(customer && customer.maintenance === PriceListOption.REQUIRED),
							disabled:
								customer &&
								(customer.maintenance === PriceListOption.NO ||
									customer.maintenance === PriceListOption.REQUIRED),
							onChange: (e): void => {
								handleChange(e)
								setFieldValue!('extraMaintenance', baseMaintenanceCost, true)
							},
							name: 'maintenance',
							id: t('QuotationMaintenance'),
						}}
						label={t('QuotationMaintenance')}
					/>
				</Box>
				<Typography
					id={`quotation-create-form-maintenance-extra-budget-label`}
					className={classes.sliderInputLabel}
					variant={'body1'}
				>
					{t('QuotationAdditionalMainBudget')}
				</Typography>
				<CustomSliderInput
					id={`quotation-create-form-maintenance-extra-budget-slider`}
					labelMax={`€${baseMaintenanceCost + 900} ${t(
						'QuotationAdditionalMainBudgetMax'
					)}`}
					labelMin={`€${baseMaintenanceCost} ${t(
						'QuotationAdditionalMainBudgetMin'
					)}`}
					iconLeft={
						<ValutaInputIconLeft
							className={classes.extraMaintenanceSliderInputIconLeft}
						/>
					}
					propsToDelegate={{
						onChange: handleChange,
						onBlur: handleBlur,
						name: 'extraMaintenance',
						value: values.extraMaintenance,
						disabled:
							customer &&
							customer.maintenance !== PriceListOption.REQUIRED &&
							!values.maintenance,
						inputProps: {
							max: baseMaintenanceCost + 900,
							min: baseMaintenanceCost,
							step: 50,
						},
					}}
				/>
			</Box>
			<Typography
				id={`quotation-create-form-administration-cost-title`}
				className={classes.title}
				variant={'h3'}
			>
				{t('QuotationAdministration')}
			</Typography>
			<FieldArray
				name="administrationCosts"
				render={(arrayHelpers): React.ReactNode => (
					<div>
						{values.administrationCosts.map((cost, index) => (
							<Box key={index}>
								<Box className={classes.closeContainer}>
									<Box onClick={(): void => arrayHelpers.remove(index)}>
										<CloseIconSvg className={classes.closeIcon} />
									</Box>
								</Box>
								<Box className={classes.accessoryHorizontalBox}>
									<Field
										name={`administrationCosts.${index}.cost`}
										render={(): React.ReactNode => (
											<CustomTextInput
												label={t('QuotationAdministrationCost')}
												className={classNames(classes.input, classes.costBox)}
												iconLeft={<ValutaInputIconLeft />}
												error={Boolean(
													getAdministrationCostsHelperText(
														errors,
														touched,
														index,
														'cost'
													)
												)}
												helperText={getAdministrationCostsHelperText(
													errors,
													touched,
													index,
													'cost'
												)}
												propsToDelegate={{
													name: `administrationCosts.${index}.cost`,
													type: 'number',
													value: values.administrationCosts[index].cost,
													error: handleAdministrationCostsError(
														errors,
														touched,
														index
													),
													onChange: handleChange,
													onBlur: handleBlur,
												}}
											/>
										)}
									/>
									<Field
										name={`administrationCosts.${index}.description`}
										render={(): React.ReactNode => (
											<CustomTextInput
												label={t('QuotationAdministrationDescription')}
												className={classNames(
													classes.input,
													classes.descriptionBox
												)}
												error={Boolean(
													getAdministrationCostsHelperText(
														errors,
														touched,
														index,
														'description'
													)
												)}
												helperText={getAdministrationCostsHelperText(
													errors,
													touched,
													index,
													'description'
												)}
												propsToDelegate={{
													name: `administrationCosts.${index}.description`,
													placeholder: t('QuotationAdministrationPlaceholder'),
													value: values.administrationCosts[index].description,
													error: handleAdministrationCostsError(
														errors,
														touched,
														index
													),
													onChange: handleChange,
													onBlur: (e): void => {
														handleBlur(e)
														trimAdministationCostsSpaces(
															values,
															'description',
															index
														)
													},
												}}
											/>
										)}
									/>
								</Box>
								<Box className={classes.divider} />
							</Box>
						))}
						<Box className={classes.buttonContainer}>
							<CustomButton
								id={`quotation-create-form-administration-cost-create-button`}
								startIcon={<AddIcon className={classes.plusIcon} />}
								type={'text'}
								text={t('QuotationAddNewAdministrationCost')}
								propsToDelegate={{
									onClick: (): void =>
										arrayHelpers.push({
											description: '',
											cost: '',
										}),
								}}
							/>
						</Box>
					</div>
				)}
			/>
		</Box>
	)
}

AdditionalInfo.propTypes = {
	touched: PropTypes.any,
	errors: PropTypes.any,
	values: PropTypes.any,
	handleChange: PropTypes.any,
	handleBlur: PropTypes.any,
	baseMaintenanceCost: PropTypes.any,
	setFieldValue: PropTypes.func.isRequired,
	customer: PropTypes.any,
}

export default AdditionalInfo
