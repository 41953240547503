import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ffffff',
	},
	input: {
		marginBottom: theme.spacing(16 / 8),
	},
	accessoryHorizontalBox: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	amountInput: {
		width: '13%',
	},
	priceBox: {
		width: '82.5%',
	},
	divider: {
		marginBottom: theme.spacing(18 / 8),
		backgroundColor: '#cacfd9',
		height: theme.spacing(1 / 8),
		borderRadius: theme.spacing(4 / 8),
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	plusIcon: {
		'&&': {
			fontSize: 29,
		},
	},
	closeContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	closeIcon: {
		cursor: 'pointer',
	},
}))

export default useStyles
