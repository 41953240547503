import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ffffff',
	},
	input: {
		marginBottom: theme.spacing(16 / 8),
	},
	accessoryHorizontalBox: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	costBox: {
		width: '30%',
	},
	descriptionBox: {
		width: '64.5%',
	},
	divider: {
		backgroundColor: '#cacfd9',
		height: theme.spacing(1 / 8),
		borderRadius: theme.spacing(4 / 8),
		marginBottom: theme.spacing(18 / 5),
	},
	buttonContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	plusIcon: {
		'&&': {
			fontSize: 29,
		},
	},
	title: {
		color: '#08080d',
		fontSize: 24,
		fontWeight: 'bold',
		marginBottom: theme.spacing(16 / 8),
	},
	extrasBox: {
		marginBottom: theme.spacing(32 / 8),
	},
	checkboxContainer: {
		display: 'flex',
	},
	checkBoxLabel: {
		color: '#08080d',
		fontSize: 16,
		alignSelf: 'center',
	},
	sliderInputLabel: {
		marginTop: theme.spacing(14 / 8),
		color: '#08080d',
		fontSize: 16,
	},
	closeContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	closeIcon: {
		cursor: 'pointer',
	},
	extraMaintenanceSliderInputIconLeft: {
		color: '#524fa2',
		fontSize: 28,
		fontWeight: 600,
	},
}))

export default useStyles
