import {Box} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

import CustomTextInput from '@components/CustomTextInput'
import {
	CreateQuotationFormFields,
	QuotationFormComponentProps,
} from '@templates/QuotationCreation'
import {useQuerySelectorAllNotranslate} from '@utils/hooks'
import {Customer, Dealer} from '@utils/types'

import useStyles from './style.hook'

type StringValues = 'reference' | 'cyclistEmail'

interface InvolvedPartiesSelectProps extends QuotationFormComponentProps {
	customers: Customer[]
	dealers: Dealer[]
	setBaseMaintenanceCost: (cost: number) => void
	customersLoading: boolean
	dealersLoading: boolean
	dealerFilter?: string
	setDealerFilter?: (companyName: string) => void
	customerFilter?: string
	setCustomerFilter?: (companyName: string) => void
}

const InvolvedPartiesSelect: React.FC<InvolvedPartiesSelectProps> = ({
	values,
	touched,
	errors,
	handleBlur,
	handleChange,
	setFieldValue,
	customers,
	dealers,
	setBaseMaintenanceCost,
	customersLoading,
	dealersLoading,
	dealerFilter,
	setDealerFilter,
	customerFilter,
	setCustomerFilter,
}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})

	const customerOptionsProps = {
		options: customers,
		getOptionLabel: (option: Customer): string =>
			option.companyName || option.toString(),
		filterOptions: (options: Customer[], state: any): Customer[] => {
			if (!state.inputValue || !state.inputValue.length) {
				return options
			}

			return options.filter((c) =>
				c.companyName
					.toLocaleLowerCase()
					.replace(/\s/g, '')
					.includes(state.inputValue.toLocaleLowerCase().replace(/\s/g, ''))
			)
		},
	}

	const dealerOptionsProps = {
		options: dealers,
		getOptionLabel: (option: Dealer): string =>
			option.name || option.toString(),
		filterOptions: (options: Dealer[], state: any): Dealer[] => {
			if (!state.inputValue || !state.inputValue.length) {
				return options
			}

			return options.filter((d) =>
				d.name
					.toLocaleLowerCase()
					.replace(/\s/g, '')
					.includes(state.inputValue.toLocaleLowerCase().replace(/\s/g, ''))
			)
		},
	}

	const getCustomerNameById = (id: number): string => {
		let name = ''
		// eslint-disable-next-line array-callback-return
		customers.map((customer: Customer): void => {
			if (customer.customerId === id) {
				name = customer.companyName || customer.toString()
			}
		})
		return name
	}

	const getDealerNameById = (id: number): string => {
		let name = ''
		// eslint-disable-next-line array-callback-return
		dealers.map((dealer: Dealer): void => {
			// TODO: remove parsing once typing returned from API is consistent
			if (parseInt(dealer.id.toString(), 10) === id) {
				name = dealer.name || dealer.toString()
			}
		})
		return name
	}

	useQuerySelectorAllNotranslate({query: 'li', toWatch: customers})
	useQuerySelectorAllNotranslate({query: 'li', toWatch: dealers})

	const trimSpaces = (
		values: CreateQuotationFormFields,
		property: StringValues
	): void => {
		values[property] = values[property].trim()
	}

	return (
		<Box className={classes.root}>
			<Autocomplete
				{...customerOptionsProps}
				value={getCustomerNameById(Number(values.customerId)) as any}
				id={`quotation-create-form-customer-select`}
				clearOnBlur={false}
				autoComplete={true}
				loading={customersLoading}
				onChange={(event: any, newValue: Customer | null): void => {
					if (!newValue || !newValue.customerId) {
						setFieldValue!('customerId', null)
						return
					}

					if (newValue.baseMaintenanceCost) {
						setBaseMaintenanceCost(newValue.baseMaintenanceCost)
					}

					setFieldValue!('customerId', newValue.customerId)
				}}
				onInputChange={(_event: React.ChangeEvent<{}>, value: string): void => {
					setCustomerFilter!(value)
				}}
				noOptionsText={
					customerFilter && customerFilter.length >= 3
						? t('AutocompleteNoOptionsWithFilterText')
						: t('AutocompleteNoOptionsWithoutFilterText3Chars')
				}
				renderInput={(params): React.ReactNode => (
					<div ref={params.InputProps.ref}>
						<CustomTextInput
							label={t('QuotationCustomerId')}
							error={(errors.customerId && touched.customerId) as boolean}
							helperText={(touched.customerId && errors.customerId) as string}
							helperTextId={errors.customerId && 'customer-helper-text'}
							className={classes.input}
							propsToDelegate={{
								...params,
								placeholder: t('QuotationCustomerPlaceholder'),
								error: (errors.customerId && touched.customerId) as boolean,
								fullWidth: true,
								onBlur: handleBlur,
								name: 'customerId',
							}}
						/>
					</div>
				)}
			/>
			<Autocomplete
				{...dealerOptionsProps}
				id={`quotation-create-form-dealer-select`}
				value={getDealerNameById(Number(values.dealerId)) as any}
				clearOnBlur={false}
				autoComplete={true}
				loading={dealersLoading}
				onChange={(_event: any, newValue: Dealer | null): void => {
					if (!newValue || !newValue.id) {
						setFieldValue!('dealerId', null)
						return
					}

					setFieldValue!('dealerId', newValue.id)
				}}
				onInputChange={(_event: React.ChangeEvent<{}>, value: string): void => {
					setDealerFilter!(value)
				}}
				noOptionsText={
					dealerFilter
						? t('AutocompleteNoOptionsWithFilterText')
						: t('AutocompleteNoOptionsWithoutFilterTextNoChars')
				}
				renderInput={(params): React.ReactNode => (
					<div ref={params.InputProps.ref}>
						<CustomTextInput
							label={t('QuotationDealerId')}
							error={(errors.dealerId && touched.dealerId) as boolean}
							helperText={(touched.dealerId && errors.dealerId) as string}
							helperTextId={errors.customerId && 'customer-helper-text'}
							className={classes.input}
							propsToDelegate={{
								...params,
								placeholder: t('QuotationDealerPlaceholder'),
								error: (errors.dealerId && touched.dealerId) as boolean,
								fullWidth: true,
								onBlur: handleBlur,
								name: 'dealerId',
							}}
						/>
					</div>
				)}
			/>
			<CustomTextInput
				id={`quotation-create-form-reference-input`}
				label={t('QuotationReference')}
				error={(errors.reference && touched.reference) as boolean}
				helperText={(touched.reference && errors.reference) as string}
				helperTextId={errors.reference && 'reference-helper-text'}
				className={classes.input}
				propsToDelegate={{
					placeholder: t('QuotationReferencePlaceholder'),
					name: 'reference',
					type: 'text',
					value: values.reference,
					error: (errors.reference && touched.reference) as boolean,
					onChange: handleChange,
					onBlur: (e): void => {
						handleBlur(e)
						trimSpaces(values, 'reference')
					},
					fullWidth: true,
				}}
			/>
			<CustomTextInput
				id={`quotation-create-form-cyclist-email-input`}
				label={t('QuotationCyclistEmail')}
				error={(errors.cyclistEmail && touched.cyclistEmail) as boolean}
				helperText={(touched.cyclistEmail && errors.cyclistEmail) as string}
				helperTextId={errors.cyclistEmail && 'cyclist-email-helper-text'}
				className={classes.input}
				propsToDelegate={{
					placeholder: t('QuotationCyclistEmailPlaceholder'),
					name: 'cyclistEmail',
					type: 'email',
					value: values.cyclistEmail,
					error: (errors.cyclistEmail && touched.cyclistEmail) as boolean,
					onChange: handleChange,
					onBlur: (e): void => {
						handleBlur(e)
						trimSpaces(values, 'cyclistEmail')
					},
					fullWidth: true,
				}}
			/>
		</Box>
	)
}

InvolvedPartiesSelect.propTypes = {
	touched: PropTypes.any,
	errors: PropTypes.any,
	values: PropTypes.any,
	handleChange: PropTypes.any,
	handleBlur: PropTypes.any,
	customers: PropTypes.any,
	dealers: PropTypes.any,
	setFieldValue: PropTypes.func,
	setBaseMaintenanceCost: PropTypes.any,
	customersLoading: PropTypes.any,
	dealersLoading: PropTypes.any,
	dealerFilter: PropTypes.string,
	setDealerFilter: PropTypes.func,
	customerFilter: PropTypes.string,
	setCustomerFilter: PropTypes.func,
}

export default InvolvedPartiesSelect
