import {Box} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import classNames from 'classnames'
import {Field, FieldArray, FormikErrors, FormikTouched} from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

import CustomButton from '@components/CustomButton'
import CustomTextInput from '@components/CustomTextInput'
import {ValutaInputIconLeftComponent as ValutaInputIconLeft} from '@components/index'
import {CloseIconSvg} from '@svg/index'
import {
	CreateQuotationFormFields,
	QuotationFormComponentProps,
} from '@templates/QuotationCreation'

import useStyles from './style.hook'

type StringValues = 'description'

const Accessories: React.FC<QuotationFormComponentProps> = ({
	values,
	handleChange,
	handleBlur,
	touched,
	errors,
}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})

	const handleAccessoriesError = (
		errors: FormikErrors<CreateQuotationFormFields>,
		touched: FormikTouched<CreateQuotationFormFields>,
		index: number
	): boolean => {
		if (errors.accessories && touched.accessories) {
			if (
				errors.accessories.length > index &&
				touched.accessories.length > index
			) {
				return (
					Boolean(errors.accessories[index]) &&
					Boolean(touched.accessories[index])
				)
			}
		}

		return false
	}

	const getAccessoriesHelperText = (
		errors: FormikErrors<CreateQuotationFormFields>,
		touched: FormikTouched<CreateQuotationFormFields>,
		index: number,
		prop: 'description' | 'quantity' | 'pricePerPiece'
	): string => {
		if (
			errors.accessories &&
			touched.accessories &&
			touched.accessories[index] &&
			errors.accessories[index]
		) {
			// @ts-ignore
			const isTouched = touched.accessories[index][prop]
			// @ts-ignore
			const error = errors.accessories[index][prop]

			return isTouched && error
		}

		return ''
	}

	const trimAccessoriesValue = (
		values: CreateQuotationFormFields,
		property: StringValues,
		index: number
	): void => {
		values.accessories[index][property] =
			values.accessories[index][property].trim()
	}

	return (
		<Box className={classes.root}>
			<FieldArray
				name="accessories"
				render={(arrayHelpers): React.ReactNode => (
					<div>
						{values.accessories.map((acc, index) => (
							<Box key={index}>
								<Box className={classes.closeContainer}>
									<Box onClick={(): void => arrayHelpers.remove(index)}>
										<CloseIconSvg className={classes.closeIcon} />
									</Box>
								</Box>
								<Field
									name={`accessories.${index}.description`}
									render={(): React.ReactNode => (
										<CustomTextInput
											label={t('QuotationAccessoryDescription')}
											className={classes.input}
											error={Boolean(
												getAccessoriesHelperText(
													errors,
													touched,
													index,
													'description'
												)
											)}
											helperText={getAccessoriesHelperText(
												errors,
												touched,
												index,
												'description'
											)}
											propsToDelegate={{
												name: `accessories.${index}.description`,
												value: values.accessories[index].description,
												error: handleAccessoriesError(errors, touched, index),
												onChange: handleChange,
												onBlur: (e): void => {
													handleBlur(e)
													trimAccessoriesValue(values, 'description', index)
												},
												placeholder: t(
													'QuotationAccessoryDescriptionPlaceholder'
												),
											}}
										/>
									)}
								/>
								<Box className={classes.accessoryHorizontalBox}>
									<Field
										name={`accessories.${index}.quantity`}
										render={(): React.ReactNode => (
											<CustomTextInput
												label={t('QuotationAccessoryAmount')}
												className={classNames(
													classes.input,
													classes.amountInput
												)}
												error={Boolean(
													getAccessoriesHelperText(
														errors,
														touched,
														index,
														'quantity'
													)
												)}
												helperText={getAccessoriesHelperText(
													errors,
													touched,
													index,
													'quantity'
												)}
												propsToDelegate={{
													name: `accessories.${index}.quantity`,
													type: 'number',
													value: values.accessories[index].quantity,
													error: handleAccessoriesError(errors, touched, index),
													onChange: handleChange,
													onBlur: handleBlur,
												}}
											/>
										)}
									/>
									<Field
										name={`accessories.${index}.pricePerPiece`}
										render={(): React.ReactNode => (
											<CustomTextInput
												label={t('QuotationAccessoryPricePerPiece')}
												className={classNames(classes.input, classes.priceBox)}
												iconLeft={<ValutaInputIconLeft />}
												error={Boolean(
													getAccessoriesHelperText(
														errors,
														touched,
														index,
														'pricePerPiece'
													)
												)}
												helperText={getAccessoriesHelperText(
													errors,
													touched,
													index,
													'pricePerPiece'
												)}
												propsToDelegate={{
													name: `accessories.${index}.pricePerPiece`,
													type: 'number',
													value: values.accessories[index].pricePerPiece,
													error: handleAccessoriesError(errors, touched, index),
													onChange: handleChange,
													onBlur: handleBlur,
												}}
											/>
										)}
									/>
								</Box>
								<Box className={classes.divider} />
							</Box>
						))}
						<Box className={classes.buttonContainer}>
							<CustomButton
								id={`quotation-create-form-create-accessory-button`}
								startIcon={<AddIcon className={classes.plusIcon} />}
								type={'text'}
								text={t('QuotationAddNewAccessory')}
								propsToDelegate={{
									onClick: (): void =>
										arrayHelpers.push({
											description: '',
											quantity: 1,
											pricePerPiece: '',
										}),
								}}
							/>
						</Box>
					</div>
				)}
			/>
		</Box>
	)
}

Accessories.propTypes = {
	touched: PropTypes.any,
	errors: PropTypes.any,
	values: PropTypes.any,
	handleChange: PropTypes.any,
	handleBlur: PropTypes.any,
}

export default Accessories
