import {
	Box,
	Grid,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@material-ui/core'
import classNames from 'classnames'
import {Formik} from 'formik'
import {FormikErrors, FormikTouched} from 'formik/dist/types'
import sha1 from 'js-sha1'
import PropTypes from 'prop-types'
import React, {
	ChangeEvent,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import {getUploadURLAPI, uploadFileAPI} from '@api/quotations-api'
import CustomButton from '@components/CustomButton'
import CustomSuccess from '@components/CustomSuccess'
import CyclisHeader from '@components/CyclisHeader'
import ErrorMessageBox from '@components/ErrorMessageBox'
import {TemplateContainerComponent} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {
	getCustomer,
	getCustomers,
	resetCustomers,
} from '@redux/slices/customers-slice'
import {
	getCyclistAccount,
	resetCyclistAccount,
} from '@redux/slices/cyclist-account-slice'
import {getManagedDealers} from '@redux/slices/dealers-slice'
import {navigate} from '@redux/slices/navigation-slice'
import {AddIconSvg} from '@svg/index'
import {
	getCyclistProfile,
	resetCyclistProfileState,
} from '@templates/CyclistProfile/cyclist-profile-slice'
import Accessories from '@templates/QuotationCreation/FormScreens/Accessories'
import AdditionalInfo from '@templates/QuotationCreation/FormScreens/AdditionalInfo'
import BicycleInfo from '@templates/QuotationCreation/FormScreens/BicycleInfo'
import InvolvedPartiesCyclistInfo from '@templates/QuotationCreation/FormScreens/InvolvedPartiesCyclistInfo'
import InvolvedPartiesSelect from '@templates/QuotationCreation/FormScreens/InvolvedPartiesSelect'
import Upload from '@templates/QuotationCreation/FormScreens/Upload'
import {
	createQuotation,
	resetQuotation,
	uploadDocument,
} from '@templates/QuotationCreation/create-quotation-slice'
import {
	createQuotationSchema,
	createQuotationWithRequiredCyclistInfoSchema,
} from '@templates/QuotationCreation/create-quotation-validation'
import {LanguageContext} from '@utils/context'
import {useThrottledEffect} from '@utils/hooks'
import {CommonProps, PriceListOption, Quotation} from '@utils/types'

import useStyles from './style.hook'

export interface QuotationFormComponentProps {
	touched: FormikTouched<CreateQuotationFormFields>
	errors: FormikErrors<CreateQuotationFormFields>
	values: CreateQuotationFormFields
	handleBlur: (eventOrString: any) => void | ((e: any) => void)
	handleChange: (
		eventOrPath: string | React.ChangeEvent<any>
	) => void | ((eventOrTextValue: string | React.ChangeEvent<any>) => void)
	setFieldValue?: (
		field: string,
		value: any,
		shouldValidate?: boolean | undefined
	) => any
}

export interface CreateQuotationFormFields {
	customerId: string
	cyclistEmail: string
	firstName: string
	lastName: string
	email: string
	phone: string
	preferredLanguage: string
	dealerId: string
	brandId: string
	model: string
	frameTypeId: string
	frameSizeId: string
	colorId: string
	typeId: string
	reference: string
	retailPrice: string
	status: string
	insurance: boolean
	maintenance: boolean
	assistance: boolean
	extraMaintenance: number
	administrationCosts: {description: string; cost: string}[]
	accessories: {description: string; quantity: number; pricePerPiece: string}[]
}

/**
 * QuotationCreation
 */
interface QuotationCreationProps extends CommonProps {
	notRequiredPlaceholderProp?: string
}

const QuotationCreation: React.FC<QuotationCreationProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const {t} = useTranslation(undefined, {useSuspense: false})
	const [activeStep, setActiveStep] = useState<number>(0)
	// A step can have multiple content pages, that's why we have a separate counter for it
	const [activeStepContent, setActiveStepContent] = useState<number>(0)
	const [baseMaintenanceCost, setBaseMaintenanceCost] = useState<number>(300)
	const [uploadedFile, setUploadedFile] = useState<File>(undefined as any)

	const {brands, types, frameTypes, frameSizes, colors} = useSelector(
		(state: RootState) => state.productData
	)
	const {
		success,
		loading: quotationCreating,
		error: creationError,
	} = useSelector((state: RootState) => state.createQuotation)
	const {dealers, loading: dealersLoading} = useSelector(
		(state: RootState) => state.dealers
	)
	const {
		customers,
		loading: customersLoading,
		customer,
	} = useSelector((state: RootState) => state.customers)
	const {cyclistId, loading: cyclistAccountLoading} = useSelector(
		(state: RootState) => state.cyclistAccount
	)
	const {cyclist} = useSelector((state: RootState) => state.cyclistProfile)

	const [dealerFilter, setDealerFilter] = useState<string>()
	const [customerFilter, setCustomerFilter] = useState<string>()

	const {activeLanguage} = useContext(LanguageContext)

	const dispatch = useDispatch()

	const steps = [
		t('QuotationCreationPageStepInvolvedParties'),
		t('QuotationCreationPageStepBicycleInfo'),
		t('QuotationCreationPageStepAccessories'),
		t('QuotationCreationPageStepAdditionalInfo'),
		t('QuotationCreationPageStepUpload'),
	]

	const handleChangeFile = (e: ChangeEvent<HTMLInputElement>): void => {
		const file = e.target.files![0]
		setUploadedFile(file)
	}

	const handleFileDelete = (): void => {
		setUploadedFile(undefined as any)
	}

	const requiredFieldsForStepContent = [
		['customerId', 'dealerId'],
		cyclistId
			? []
			: ['firstName', 'lastName', 'email', 'phone', 'preferredLanguage'],
		[
			'brandId',
			'model',
			'typeId',
			'frameTypeId',
			'frameSizeId',
			'colorId',
			'retailPrice',
		],
		[],
		[],
	]

	const validationSchema = cyclistId
		? createQuotationSchema(t)
		: createQuotationWithRequiredCyclistInfoSchema(t)

	const stepContent = (
		props?: QuotationFormComponentProps
	): {step: number; content: React.ReactNode}[] => [
		{
			step: 0,
			content: (
				<InvolvedPartiesSelect
					{...(props as any)}
					customers={customers}
					dealers={dealers}
					setBaseMaintenanceCost={setBaseMaintenanceCost}
					customersLoading={customersLoading}
					dealersLoading={dealersLoading}
					dealerFilter={dealerFilter}
					setDealerFilter={setDealerFilter}
					customerFilter={customerFilter}
					setCustomerFilter={setCustomerFilter}
				/>
			),
		},
		{
			step: 0,
			content: <InvolvedPartiesCyclistInfo {...(props as any)} />,
		},
		{
			step: 1,
			content: (
				<BicycleInfo
					{...(props as any)}
					brands={brands}
					types={types}
					frameTypes={frameTypes}
					frameSizes={frameSizes}
					colors={colors}
				/>
			),
		},
		{
			step: 2,
			content: <Accessories {...(props as any)} />,
		},
		{
			step: 3,
			content: (
				<AdditionalInfo
					{...(props as any)}
					baseMaintenanceCost={baseMaintenanceCost}
					customer={customer}
				/>
			),
		},
		{
			step: 4,
			content: (
				<Upload
					uploadedFile={uploadedFile}
					handleChangeFile={handleChangeFile}
					handleFileDelete={handleFileDelete}
				/>
			),
		},
	]

	const initialValues: CreateQuotationFormFields = {
		customerId:
			cyclist && cyclist.customerId ? cyclist.customerId.toString() : '',
		cyclistEmail: cyclist && cyclist.email ? cyclist.email : '',
		firstName: '',
		lastName: '',
		email: '',
		phone: undefined as any,
		preferredLanguage: undefined as any,
		dealerId: undefined as any,
		brandId: '',
		model: '',
		frameTypeId: '',
		frameSizeId: '',
		colorId: '',
		typeId: '',
		reference: '',
		retailPrice: '',
		status: '',
		insurance: false,
		maintenance: false,
		assistance: false,
		extraMaintenance: undefined as any,
		administrationCosts: [],
		accessories: [],
	}

	const fieldHasErrors = (
		errors: FormikErrors<CreateQuotationFormFields>,
		field: string
	): boolean => Boolean(errors[field] && errors[field].length)

	const fetchNewCustomerData = (customerId: number): void => {
		if (
			customerId &&
			activeStepContent === 0 &&
			(!customer || customerId === customer.customerId)
		) {
			dispatch(getCustomer(customerId))
		}
	}

	const resetLocalContainerState = (): void => {
		setActiveStep(0)
		setActiveStepContent(0)
		setBaseMaintenanceCost(0)
	}

	const goToNextStep = (_: any, increase = 1): void => {
		const nextContentStep = activeStepContent + increase
		setActiveStepContent(nextContentStep)
		setActiveStep(stepContent()[nextContentStep].step)
	}

	const goToPreviousStep = (_: any, decrease = 1): void => {
		/**
		 * If we have a cyclistId that already exists we go back 2 steps
		 * on the bicycle info page to skip the cyclist information page
		 */
		if (cyclistId && activeStepContent === 2) {
			decrease = 2
		}

		const previousContentStep = activeStepContent - decrease
		setActiveStepContent(previousContentStep)
		setActiveStep(stepContent()[previousContentStep].step)
	}

	/**
	 * Merge the values from the form with state of this container
	 * This is to merge the cyclistId and dealerId retrieved from backend/JWT
	 */
	const mergeFormValuesWithContainerState = (
		values: CreateQuotationFormFields
	): Quotation => {
		const assistance =
			customer && customer.assistance === PriceListOption.REQUIRED
				? true
				: values.assistance
		const maintenance =
			customer && customer.maintenance === PriceListOption.REQUIRED
				? true
				: values.maintenance
		const insurance =
			customer && customer.insurance === PriceListOption.REQUIRED
				? true
				: values.insurance
		const extraMaintenance =
			maintenance && values.extraMaintenance
				? Number(values.extraMaintenance) - Number(baseMaintenanceCost)
				: 0

		const customerId = Number(values.customerId)
		const parsedDealerId = Number(values.dealerId)

		const accessories = values.accessories.map((acc) => {
			return {...acc, pricePerPiece: Number(acc.pricePerPiece)}
		})
		const administrationCosts = values.administrationCosts.map((adminCost) => {
			return {...adminCost, cost: Number(adminCost.cost)}
		})

		return {
			...values,
			cyclistId: cyclistId || undefined,
			extraMaintenance,
			dealerId: parsedDealerId,
			customerId,
			assistance,
			maintenance,
			insurance,
			accessories,
			administrationCosts,
		}
	}

	const submitQuotationForm = async (
		values: CreateQuotationFormFields
	): Promise<void> => {
		let quotationDocument: any

		// TODO: this should be in an redux flow
		if (uploadedFile) {
			dispatch(uploadDocument())
			const responseGetUploadUrl = await getUploadURLAPI()
			await uploadFileAPI(responseGetUploadUrl.uploadUrl, uploadedFile)

			const fileBinary = await new Response(uploadedFile).arrayBuffer()

			quotationDocument = {
				identifier: responseGetUploadUrl.identifier,
				checksum: sha1.create().update(fileBinary).hex(),
			}
		}

		dispatch(
			createQuotation({
				...mergeFormValuesWithContainerState(values),
				quotationDocument,
			})
		)
	}

	/**
	 * Check whether a cyclist with the provided email already exists.
	 * If the cyclist does not yet exists, more cyclist information needs to be given on page 2.
	 * If the cyclist already exists, these fields should not be filled in. Navigate to page 3
	 */
	const checkCyclistAccountAlreadyExists = (email: string): void => {
		dispatch(getCyclistAccount(email))
	}

	/**
	 * Get URL parameters
	 */
	useEffect(() => {
		const {search} = location!
		const params = new URLSearchParams(search)
		const cyclistId = parseInt(params.get('cyclist')!, 10)
		const bicycleId = parseInt(params.get('bicycle')!, 10)

		if (cyclistId && bicycleId) {
			dispatch(getCyclistProfile(cyclistId, bicycleId))
		}
	}, [location])

	useEffect(() => {
		// Initial state, don't do anything
		// Backend return null, not undefined so this check is safe
		if (cyclistId === undefined) {
			return
		}

		if (cyclistId) {
			goToNextStep(undefined, 2)
		} else {
			goToNextStep(undefined)
		}
	}, [cyclistAccountLoading])

	/**
	 * Set basemaintenance cost when customer is received
	 */
	useEffect(() => {
		if (customer && customer.baseMaintenanceCost) {
			setBaseMaintenanceCost(customer.baseMaintenanceCost)
		}
	}, [customer])

	/**
	 * Reset container state on success
	 */
	useEffect(() => {
		if (success) {
			resetLocalContainerState()
		}
	}, [success])

	/**
	 * Reset container state on unmount
	 */
	useEffect(() => {
		return (): void => {
			dispatch(resetCyclistAccount())
			dispatch(resetQuotation())
			dispatch(resetCyclistProfileState())
			resetLocalContainerState()
		}
	}, [])

	/**
	 * Handle customer autocomplete input change by filtering customers trough API
	 */
	const filterCustomers = (companyName: string): void => {
		if (companyName && companyName.length >= 3) {
			dispatch(getCustomers(companyName))
		} else {
			dispatch(resetCustomers())
		}
	}

	useThrottledEffect(() => filterCustomers(customerFilter!), 1000, [
		customerFilter,
	])

	// Get managed dealers on page render
	useEffect(() => {
		dispatch(getManagedDealers())
	}, [])

	return (
		<TemplateContainerComponent
			id={'create-quotation-container'}
			className={classNames(classes.root, {
				[classes.backgroundSuccess]: success,
			})}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			{success ? (
				<CustomSuccess
					icon={<AddIconSvg />}
					headerText={t('QuotationCreationSuccessTitle')}
					id={'quotation-create-success-container'}
					subTitle={
						<Typography
							className={classNames(classes.subTitle, classes.centeredText)}
							id={'quotation-create-success-subtitle'}
							variant={'body1'}
						>
							{t('QuotationCreationSuccessSubTitle')}
						</Typography>
					}
					buttonText={t('QuotationCreationSuccessButtonText')}
					buttonAction={(): void => {
						dispatch(navigate(`/${activeLanguage}/app/quotations`))
					}}
				/>
			) : (
				<>
					<Box
						id={'quotation-create-stepper-container'}
						className={classes.stepperContainer}
					>
						<Stepper
							id={'quotation-create-stepper'}
							className={classes.stepper}
							activeStep={activeStep}
						>
							{steps.map((label, index) => (
								<Step id={`quotation-create-step-${index}`} key={index}>
									<StepLabel id={`quotation-create-step-label-${index}`}>
										{label}
									</StepLabel>
								</Step>
							))}
						</Stepper>
					</Box>
					<Formik
						initialValues={initialValues}
						onSubmit={submitQuotationForm}
						validationSchema={validationSchema}
					>
						{({
							values,
							handleChange,
							handleBlur,
							handleSubmit,
							errors,
							touched,
							submitForm,
							setFieldValue,
						}): ReactNode => (
							<form id={`quotation-create-form`} onSubmit={handleSubmit}>
								<Grid spacing={8} container>
									<Grid item xs={3} />
									<Grid item xs={6}>
										<Box
											id={`quotation-create-form-container`}
											className={classes.formContainer}
										>
											{creationError && (
												<ErrorMessageBox
													id="quotation-creation-error-message-box"
													className={classes.errorMessageBox}
													errorMessage={t(creationError)}
												/>
											)}
											{
												stepContent({
													values,
													touched,
													errors,
													handleBlur,
													handleChange,
													setFieldValue,
												})[activeStepContent].content
											}
										</Box>
										<Box
											id={`quotation-create-form-button-container`}
											className={classNames(
												classes.buttonContainer,
												activeStepContent === 0 &&
													classes.buttonContainerSingle,
												activeStepContent === 5 && classes.buttonContainerLast
											)}
										>
											{activeStepContent !== 0 && (
												<CustomButton
													id={`quotation-create-form-back-button`}
													className={classNames(
														classes.ctaButton,
														classes.backButton
													)}
													text={t('QuotationCreationPageBackButton')}
													propsToDelegate={{onClick: goToPreviousStep}}
												/>
											)}
											{activeStepContent === 5 ? (
												<CustomButton
													id={`quotation-create-form-submit-button`}
													className={classes.ctaButton}
													text={t('QuotationCreateButton')}
													propsToDelegate={{
														onClick: (): void => {
															submitForm()
														},
														disabled: quotationCreating || !uploadedFile,
													}}
												/>
											) : (
												<CustomButton
													id={`quotation-create-form-next-button`}
													className={classes.ctaButton}
													text={t('QuotationCreationPageNextButton')}
													propsToDelegate={{
														onClick: (): void => {
															fetchNewCustomerData(Number(values.customerId))
															if (
																activeStepContent === 0 &&
																values.cyclistEmail
															) {
																checkCyclistAccountAlreadyExists(
																	values.cyclistEmail
																)
															} else {
																goToNextStep(undefined)
															}
														},
														type: 'button',
														disabled:
															(activeStep === 0 &&
																(!values.customerId || !values.dealerId)) ||
															(activeStep === 2 &&
																fieldHasErrors(errors, 'accessories')) ||
															(activeStep === 3 &&
																fieldHasErrors(
																	errors,
																	'administrationCosts'
																)) ||
															quotationCreating ||
															cyclistAccountLoading ||
															requiredFieldsForStepContent[
																activeStepContent
															].some((field) => {
																return (
																	((touched as any)[field] === undefined &&
																		[undefined, [], ''].includes(
																			(values as any)[field]
																		)) ||
																	(errors as any)[field]
																)
															}),
													}}
												/>
											)}
										</Box>
									</Grid>
									<Grid item xs={3} />
								</Grid>
							</form>
						)}
					</Formik>
				</>
			)}
		</TemplateContainerComponent>
	)
}

QuotationCreation.propTypes = {
	notRequiredPlaceholderProp: PropTypes.any,
	location: PropTypes.any,
}

export default QuotationCreation
