import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ffffff',
		marginRight: `calc(13% + ${LARGE_GUTTER_SIZE * 2}px)`,
		marginLeft: `calc(13% + ${LARGE_GUTTER_SIZE * 2}px)`,
	},
	selectInput: {
		marginTop: theme.spacing(8 / 8),
		marginBottom: theme.spacing(16 / 8),
	},
	input: {
		marginBottom: theme.spacing(16 / 8),
	},
}))

export default useStyles
