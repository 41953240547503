import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

/**
 * Component-scoped styles which should be used as a hook
 * inside QuotationCreation functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ffffff',
	},
	backgroundSuccess: {
		backgroundColor: '#edf2f8',
	},
	stepperContainer: {
		marginTop: theme.spacing(5 / 8),
		backgroundColor: '#edf2f8',
	},
	stepper: {
		backgroundColor: '#edf2f8',
		marginLeft: '15%',
		marginRight: '15%',
	},
	buttonContainer: {
		marginTop: theme.spacing(120 / 8),
		display: 'flex',
		justifyContent: 'space-between',
		marginRight: `calc(13% + ${LARGE_GUTTER_SIZE * 2}px)`,
		marginLeft: `calc(13% + ${LARGE_GUTTER_SIZE * 2}px)`,
		paddingBottom: theme.spacing(25 / 8),
	},
	buttonContainerSingle: {
		justifyContent: 'center',
		marginRight: `calc(13% + ${LARGE_GUTTER_SIZE * 2}px)`,
		marginLeft: `calc(13% + ${LARGE_GUTTER_SIZE * 2}px)`,
	},
	buttonContainerLast: {
		marginTop: theme.spacing(20 / 8),
	},
	ctaButton: {
		width: `calc(50% - ${LARGE_GUTTER_SIZE}px)`,
		padding: theme.spacing(12 / 8, 0),
	},
	backButton: {
		backgroundColor: `#f1f2f6`,
		color: `#524fa2`,
		'&:hover': {
			backgroundColor: 'rgba(241, 242, 246, 0.8)',
			borderColor: 'rgba(255, 255, 255, 1)',
		},
		'&:active': {
			backgroundColor: 'rgba(241, 242, 246, 0.7)',
			borderColor: 'rgba(255, 255, 255, 1)',
		},
	},
	formContainer: {
		marginTop: theme.spacing(56 / 8),
	},
	centeredText: {
		textAlign: 'center',
	},
	subTitle: {
		color: '#7e7e80',
		fontSize: 16,
	},
	errorMessageBox: {
		marginTop: theme.spacing(12 / 8),
		marginBottom: theme.spacing(16 / 8),
	},
}))

export default useStyles
