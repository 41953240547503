import {makeStyles} from '@material-ui/core/styles'

import {LARGE_GUTTER_SIZE} from '@styles/mui-theme'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ffffff',
		display: 'flex',
		justifyContent: 'space-between',
		flexWrap: 'wrap',
	},
	input: {
		marginTop: theme.spacing(8 / 8),
		marginBottom: theme.spacing(16 / 8),
		width: `calc(50% - ${LARGE_GUTTER_SIZE}px)`,
	},
	selectInput: {
		marginTop: theme.spacing(8 / 8),
		marginBottom: theme.spacing(16 / 8),
	},
	selectContainer: {
		width: `calc(50% - ${LARGE_GUTTER_SIZE}px)`,
	},
}))

export default useStyles
