import {Box} from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import {useTranslation} from 'react-i18next'

import CustomSelect from '@components/CustomSelect'
import CustomTextInput from '@components/CustomTextInput'
import InfoBox from '@components/InfoBox'
import {
	CreateQuotationFormFields,
	QuotationFormComponentProps,
} from '@templates/QuotationCreation'

import useStyles from './style.hook'

type StringValues = 'firstName' | 'lastName' | 'email' | 'phone'

const InvolvedPartiesCyclistInfo: React.FC<QuotationFormComponentProps> = ({
	values,
	touched,
	errors,
	handleBlur,
	handleChange,
}) => {
	const classes = useStyles()
	const {t} = useTranslation(undefined, {useSuspense: false})

	const languageSelectItems = [
		{
			value: 'en_US',
			label: 'English',
		},
		{
			value: 'nl_BE',
			label: 'Nederlands',
		},
		{
			value: 'fr_BE',
			label: 'Français',
		},
	]

	const trimSpaces = (
		values: CreateQuotationFormFields,
		property: StringValues
	): void => {
		values[property] = values[property].trim()
	}

	return (
		<Box className={classes.root}>
			{values.cyclistEmail && (
				<InfoBox
					id="involved-parties-cyclist-info-error-message-box"
					className={classes.errorMessage}
					message={t('QuotationCreationCouldNotFindCyclist', {
						email: values.cyclistEmail,
					})}
				/>
			)}
			<Box className={classes.nameBox}>
				<CustomTextInput
					id={`quotation-create-form-first-name-input`}
					label={t('QuotationFirstName')}
					error={(errors.firstName && touched.firstName) as boolean}
					helperText={(touched.firstName && errors.firstName) as string}
					helperTextId={errors.firstName && 'first-name-helper-text'}
					className={classes.halfInput}
					propsToDelegate={{
						placeholder: t('QuotationFirstNamePlaceholder'),
						name: 'firstName',
						type: 'text',
						value: values.firstName,
						error: (errors.firstName && touched.firstName) as boolean,
						onChange: handleChange,
						onBlur: (e): void => {
							handleBlur(e)
							trimSpaces(values, 'firstName')
						},
					}}
				/>
				<CustomTextInput
					id={`quotation-create-form-last-name-input`}
					label={t('QuotationLastName')}
					error={(errors.lastName && touched.lastName) as boolean}
					helperText={(touched.lastName && errors.lastName) as string}
					helperTextId={errors.lastName && 'last-name-helper-text'}
					className={classes.halfInput}
					propsToDelegate={{
						placeholder: t('QuotationLastNamePlaceholder'),
						name: 'lastName',
						type: 'text',
						value: values.lastName,
						error: (errors.lastName && touched.lastName) as boolean,
						onChange: handleChange,
						onBlur: (e): void => {
							handleBlur(e)
							trimSpaces(values, 'lastName')
						},
					}}
				/>
			</Box>
			<CustomTextInput
				id={`quotation-create-form-email-input`}
				label={t('QuotationEmailAddress')}
				error={(errors.email && touched.email) as boolean}
				helperText={(touched.email && errors.email) as string}
				helperTextId={errors.email && 'email-helper-text'}
				className={classes.input}
				propsToDelegate={{
					placeholder: t('QuotationEmailAddressPlaceholder'),
					name: 'email',
					type: 'email',
					value: values.email,
					error: (errors.email && touched.email) as boolean,
					onChange: handleChange,
					onBlur: (e): void => {
						handleBlur(e)
						trimSpaces(values, 'email')
					},
					fullWidth: true,
				}}
			/>
			<CustomTextInput
				id={`quotation-create-form-phone-input`}
				label={t('QuotationPhone')}
				error={(errors.phone && touched.phone) as boolean}
				helperText={(touched.phone && errors.phone) as string}
				helperTextId={errors.phone && 'phone-helper-text'}
				className={classes.input}
				propsToDelegate={{
					placeholder: t('QuotationPhonePlaceholder'),
					name: 'phone',
					type: 'tel',
					value: values.phone,
					error: (errors.phone && touched.phone) as boolean,
					onChange: handleChange,
					onBlur: (e): void => {
						handleBlur(e)
						trimSpaces(values, 'phone')
					},
					fullWidth: true,
				}}
			/>
			<CustomSelect
				id={'quotation-create-form-language-select'}
				label={t('QuotationLanguage')}
				className={classes.selectInput}
				items={languageSelectItems}
				error={
					(errors.preferredLanguage && touched.preferredLanguage) as boolean
				}
				helperText={
					(touched.preferredLanguage && errors.preferredLanguage) as string
				}
				helperTextId={
					errors.preferredLanguage && 'preferred-language-helper-text'
				}
				placeholder={t('QuotationCreationSelectLanguagePlaceholder')}
				propsToDelegate={{
					name: 'preferredLanguage',
					value: values.preferredLanguage,
					onChange: handleChange,
					onBlur: handleBlur,
				}}
			/>
		</Box>
	)
}

InvolvedPartiesCyclistInfo.propTypes = {
	touched: PropTypes.any,
	errors: PropTypes.any,
	values: PropTypes.any,
	handleChange: PropTypes.any,
	handleBlur: PropTypes.any,
}

export default InvolvedPartiesCyclistInfo
