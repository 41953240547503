import {Typography} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {ChangeEvent} from 'react'
import {useTranslation} from 'react-i18next'

import {FileUploadComponent as FileUpload} from '@components/index'
import {CommonProps} from '@utils/types'
import {FileType} from '@utils/types/file-types'

import useStyles from './style.hook'

interface UploadProps extends CommonProps {
	uploadedFile: File
	handleChangeFile: (e: ChangeEvent<HTMLInputElement>) => void
	handleFileDelete: () => void
}

const Upload: React.FC<UploadProps> = ({
	uploadedFile,
	handleChangeFile,
	handleFileDelete,
}) => {
	const classes = useStyles()

	const {t} = useTranslation(undefined, {useSuspense: false})

	return (
		<div>
			<Typography className={classes.uploadTitle} variant="h3">
				{t('QuotationUploadTitle')}
			</Typography>
			<Typography className={classes.uploadDescription} variant="body1">
				{t('QuotationUploadDescription')}
			</Typography>
			<FileUpload
				className={classes.fileUpload}
				uploadedFile={uploadedFile}
				handleChangeFile={handleChangeFile}
				handleFileDelete={handleFileDelete}
				allowedFileTypes={FileType.PDF}
			/>
		</div>
	)
}

Upload.propTypes = {
	uploadedFile: PropTypes.any,
	handleChangeFile: PropTypes.func.isRequired,
	handleFileDelete: PropTypes.func.isRequired,
}

export default Upload
